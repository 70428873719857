import React from "react";
import { Heading } from "./Typography/Heading";
import { WiderPageSection } from "./WiderPageSection";
import { Paragraph } from "./Typography/Paragraph";
import { List } from "./List";
import { Flex } from "./ThemeFlex";
import { RouteHeading } from "./RouteHeading";
import { PARTNER_PROGRAM_PATH } from "../constants/urls";
import { UnderlinedLink } from "./Link/Hyperlink";
import styled from "styled-components";
export const PartnersDescription = () => (
    <WiderPageSection>
        <RouteHeading
            title={"Find an Easy Agile Partner"}
            subtitle={
                "Our verified solutions partners can help your team get on the ground running."
            }
        />
        <Flex alignItems="start" flexDirection="column">
            <Paragraph>
                Easy Agile’s verified partners can assist your business with
                installing, licensing, and using any Easy Agile app. Working
                with a partner makes it easy to manage Jira enhancements in your
                language and timezone.
            </Paragraph>

            <NoMarginHeading level="h4" levelStyle="h4">
                Our verified partners can help you:
            </NoMarginHeading>
            <List isBullets={true}>
                <li>
                    <strong>Install&nbsp;</strong>and configure Easy Agile
                    products
                </li>
                <li>
                    <strong>Demo&nbsp;</strong>Easy Agile products to your teams
                    and stakeholders
                </li>
                <li>
                    Grow your <strong>Agile</strong> capabilities
                </li>
                <li>
                    Consult on SAFe® practices as a
                    <strong>&nbsp;Scaled Agile</strong> Partner
                </li>
            </List>
            <span>
                Learn how to{" "}
                <UnderlinedLink href={PARTNER_PROGRAM_PATH}>
                    become an Easy Agile verified partner
                </UnderlinedLink>
                .
            </span>

            <Heading level="h4" levelStyle="h4">
                Easy Agile Verified Partners
            </Heading>
            <p>Below are a list of Easy Agile verified partners by country.</p>
        </Flex>
    </WiderPageSection>
);

const NoMarginHeading = styled(Heading)`
    margin: 0px;
`;
