import { gridSquares } from "../../styles/grid";
import { makePartnerPageSlug } from "../../util/makePartnerPageSlug";
import { PartnerNameButton } from "./PartnerNameButton";
import React, { FC } from "react";
import { PARTNER_TABLE_MOBILE_MAX_WIDTH } from "./styles";
import styled from "styled-components";

const PartnerList = styled.ul`
    width: 100%;
    max-width: ${(p) => p.theme.maxWidth.paragraph};

    margin-top: 2em;
    margin-bottom: 2em;
    @media (min-width: ${PARTNER_TABLE_MOBILE_MAX_WIDTH}px) {
        display: none;
    }
`;

const PartnerLi = styled.li`
    display: flex;
    flex-direction: column;

    :not(:first-child) {
        margin-top: ${gridSquares(1)};
    }
`;

interface PropsType {
    partners: GatsbyTypes.PartnerQueryQuery["allSanityPartner"]["nodes"];
    sortFunction: (
        a: GatsbyTypes.SanityPartner,
        b: GatsbyTypes.SanityPartner
    ) => 1 | -1;
}

const PartnerMobile: FC<PropsType> = (props) => {
    const { partners, sortFunction } = props;

    return (
        <PartnerList>
            {partners
                .concat()
                .sort(sortFunction)
                .map((partner) => {
                    const name = partner.partnerName;
                    return (
                        <PartnerLi key={name}>
                            <PartnerNameButton
                                to={makePartnerPageSlug({
                                    partnerName: partner.partnerName,
                                    slug: partner.slug,
                                })}
                            >
                                {partner.partnerName}
                            </PartnerNameButton>
                        </PartnerLi>
                    );
                })}
        </PartnerList>
    );
};

export default PartnerMobile;
