import React from "react";
import { gridSquares } from "../../styles/grid";
import styled from "styled-components";
import { regular12Type } from "../../styles/typography";
import { WIDTH } from "../Partners/Capabilities";

const Ul = styled.ul`
    margin-top: ${gridSquares(1)};
    display: flex;

    li {
        width: ${WIDTH};

        text-align: center;
        ${regular12Type}
    }
`;

export const CapabilitiesHeadings = () => (
    <Ul>
        <li>Install</li>
        <li>Demo</li>
        <li>Agile</li>
        <li>Scaled Agile</li>
    </Ul>
);
