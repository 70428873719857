import { graphql, useStaticQuery } from "gatsby";
import PartnerDesktop from "./PartnerDesktop";
import PartnerMobile from "./PartnerMobile";
import React from "react";
import { WiderPageSection } from "../WiderPageSection";

export enum HEADINGS {
    NAME = "name",
    COUNTRY = "country",
    CAPABILITIES = "capabilities",
}

const sortPartnersByKey =
    (sortKey: HEADINGS, sortOrder: boolean) =>
    (a: GatsbyTypes.SanityPartner, b: GatsbyTypes.SanityPartner) => {
        if (!a || !b) {
            return -1;
        }

        const [aValue, bValue] = [a, b].map((value) => {
            switch (sortKey) {
                case HEADINGS.COUNTRY:
                    return value.partnerContacts[0].countries[0].toLowerCase();
                case HEADINGS.CAPABILITIES:
                    return -getCapabilityCount(value);
                case HEADINGS.NAME:
                default:
                    return value.partnerName.toLowerCase();
            }
        });

        if (
            aValue === bValue &&
            [HEADINGS.COUNTRY, HEADINGS.CAPABILITIES].includes(sortKey)
        ) {
            return sortPartnersByKey(HEADINGS.NAME, sortOrder)(a, b);
        }

        return sortOrder === aValue > bValue ? 1 : -1;
    };

function getCapabilityCount(partner: GatsbyTypes.SanityPartner) {
    return [
        partner.canInstall,
        partner.canDemo,
        partner.canOptimize,
        partner.scaledAgile,
    ].filter(Boolean).length;
}

export const PartnerTable = () => {
    const queryResponse = useStaticQuery<GatsbyTypes.PartnerQueryQuery>(graphql`
        query PartnerQuery {
            allSanityPartner {
                nodes {
                    canDemo
                    canInstall
                    canOptimize
                    partnerContacts {
                        countries
                        emailAddress
                        phoneNumber
                    }
                    partnerName
                    scaledAgile
                    slug {
                        current
                    }
                }
            }
        }
    `);

    const partners = queryResponse.allSanityPartner.nodes;

    return (
        <WiderPageSection>
            <PartnerDesktop
                partners={partners}
                sortFunction={sortPartnersByKey}
            />
            <PartnerMobile
                partners={partners}
                sortFunction={sortPartnersByKey(HEADINGS.NAME, true)}
            />
        </WiderPageSection>
    );
};
