const getPartnerCountries = (partner: GatsbyTypes.SanityPartner): string[] => {
    const [first, ...rest] = [
        ...partner.partnerContacts.map(
            (contact: GatsbyTypes.SanityPartnerContact) => contact.countries
        ),
    ];
    return first?.concat(...rest);
};

export default getPartnerCountries;
