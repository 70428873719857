import { PartnersDescription } from "../components/PartnersDescription";
import { PartnerTable } from "../components/PartnerTable";
import React from "react";
import { Head } from "../components/Head";
import { SEOOnly } from "../components/SEOOnly";
import { ContentGroups } from "../constants/contentGroups";

const Partners = () => (
    <>
        <Head
            contentGroup={ContentGroups.partners}
            title="Find an Easy Agile Partner"
        />
        <PartnersDescription />
        <PartnerTable />
    </>
);

export default Partners;
