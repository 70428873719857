import { Capabilities } from "../Partners/Capabilities";
import getPartnerCountries from "../../util/getPartnerCountries";
import { gridSquares } from "../../styles/grid";
import { HEADINGS } from "./index";
import { makePartnerPageSlug } from "../../util/makePartnerPageSlug";
import { PartnerNameButton } from "./PartnerNameButton";
import React, { FC } from "react";
import { PARTNER_TABLE_DESKTOP_MIN_WIDTH } from "./styles";
import styled, { css } from "styled-components";
import { bold12Type } from "../../styles/typography";
import { CapabilitiesHeadings } from "./CapabilitiesHeadings";

const Table = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
    margin-top: 2em;
    margin-bottom: 2em;

    @media (max-width: ${PARTNER_TABLE_DESKTOP_MIN_WIDTH}px) {
        display: none;
    }
`;

const Th = styled.th`
    height: 72px;

    position: sticky;
    top: calc(${(p) => p.theme.height.navBar} - 1px);

    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    background: ${(p) => p.theme.color.secondaryOne.main};

    &:not(:first-child) {
        border-left: 1px solid;
    }
`;

const Button = styled.button`
    width: 100%;
    height: 72px;
    padding: ${gridSquares(1)};

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ButtonText = styled.span`
    ${bold12Type}
`;

const TableData = styled.td`
    padding: ${gridSquares(0.5)} ${gridSquares(1)};

    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Ul = styled.ul`
    display: flex;
    flex-direction: column;

    > :not(:last-child) {
        margin-bottom: ${gridSquares(0.5)};
    }
`;

const Svg = styled.svg<{ isHighlight: boolean; isRotate: boolean }>`
    width: 28px;

    position: absolute;
    top: ${gridSquares(0.25)};
    right: ${gridSquares(0.25)};

    path {
        fill: ${(p) => p.theme.color.secondaryOne.contrastText};
        transform-origin: center center;

        ${(p) =>
            p.isHighlight &&
            css`
                fill: ${p.theme.color.accentTwo.main};
            `}

        ${(p) =>
            p.isRotate &&
            css`
                transform: rotate(180deg);
            `}
    }
`;

const Arrow = (props: { isHighlight: boolean; isRotate: boolean }) => (
    <Svg {...props} width="24" height="24" viewBox="0 0 24 24">
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
    </Svg>
);

const Tr = styled.tr`
    padding: 0 ${gridSquares(1)};

    :nth-child(even) {
        background: ${(p) => p.theme.color.background.dim};
    }
`;

interface PropsType {
    partners: GatsbyTypes.PartnerQueryQuery["allSanityPartner"]["nodes"];
    sortFunction: (
        sortKey: HEADINGS,
        sortOrder: boolean
    ) => (a: GatsbyTypes.SanityPartner, b: GatsbyTypes.SanityPartner) => 1 | -1;
}

const PartnerDesktop: FC<PropsType> = (props) => {
    const { partners, sortFunction } = props;
    const [sortKey, setSortKey] = React.useState(HEADINGS.CAPABILITIES);
    const [sortOrder, setSortOrder] = React.useState(true); // true = a -> b and false = b -> a
    const sortTable = sortFunction(sortKey, sortOrder);

    const headings = [HEADINGS.NAME, HEADINGS.COUNTRY, HEADINGS.CAPABILITIES];
    const onHeadingClick = (heading: string) => {
        sortKey === heading ? setSortOrder(!sortOrder) : setSortKey(heading);
    };

    return (
        <Table>
            <thead>
                <tr>
                    <Th>
                        <Button onClick={() => onHeadingClick(HEADINGS.NAME)}>
                            <ButtonText>Name</ButtonText>
                            <Arrow
                                isHighlight={sortKey === HEADINGS.NAME}
                                isRotate={sortOrder}
                            />
                        </Button>
                    </Th>
                    <Th>
                        <Button
                            onClick={() => onHeadingClick(HEADINGS.COUNTRY)}
                        >
                            <ButtonText>Country</ButtonText>
                            <Arrow
                                isHighlight={sortKey === HEADINGS.COUNTRY}
                                isRotate={sortOrder}
                            />
                        </Button>
                    </Th>
                    <Th>
                        <Button
                            onClick={() =>
                                onHeadingClick(HEADINGS.CAPABILITIES)
                            }
                        >
                            <ButtonText>Capabilities</ButtonText>
                            <CapabilitiesHeadings />
                            <Arrow
                                isHighlight={sortKey === HEADINGS.CAPABILITIES}
                                isRotate={sortOrder}
                            />
                        </Button>
                    </Th>
                </tr>
            </thead>
            <tbody>
                {partners
                    .concat()
                    .sort(sortTable)
                    .map((partner) => {
                        const {
                            canDemo,
                            canInstall,
                            canOptimize,
                            partnerName,
                            scaledAgile,
                            slug,
                        } = partner;
                        return (
                            <Tr key={partnerName}>
                                <TableData>
                                    <PartnerNameButton
                                        to={makePartnerPageSlug({
                                            partnerName,
                                            slug,
                                        })}
                                    >
                                        {partnerName}
                                    </PartnerNameButton>
                                </TableData>
                                <TableData>
                                    <Ul>
                                        {getPartnerCountries(partner).map(
                                            (country) => (
                                                <li
                                                    key={`${partnerName}-${country}`}
                                                >
                                                    {country}
                                                </li>
                                            )
                                        )}
                                    </Ul>
                                </TableData>
                                <TableData>
                                    <Capabilities
                                        canDemo={canDemo}
                                        canInstall={canInstall}
                                        canOptimize={canOptimize}
                                        gutterSize={2}
                                        scaledAgile={scaledAgile}
                                    />
                                </TableData>
                            </Tr>
                        );
                    })}
            </tbody>
        </Table>
    );
};

export default PartnerDesktop;
