import { gridSquares } from "../../styles/grid";
import React, { ReactElement } from "react";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import styled from "styled-components";

export const WIDTH = `96px`;

interface Props {
    canDemo: boolean;
    canInstall: boolean;
    canOptimize: boolean;
    scaledAgile: boolean;
}

const Ul = styled.ul<{ gutterSize: number }>`
    display: flex;
`;

export function Capabilities({
    canDemo,
    canInstall,
    canOptimize,
    scaledAgile,
}: Props): ReactElement {
    return (
        <Ul>
            <CapabilityItem hasCapability={canInstall} capability="Install" />
            <CapabilityItem hasCapability={canDemo} capability="Demo" />
            <CapabilityItem hasCapability={canOptimize} capability="Agile" />
            <CapabilityItem
                hasCapability={scaledAgile}
                capability="Scaled Agile"
            />
        </Ul>
    );
}

const Li = styled.li<{ hasCapability: boolean }>`
    width: ${WIDTH};
    padding-top: ${gridSquares(0.4)};

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Dot = styled.div`
    width: ${gridSquares(0.5)};
    height: ${gridSquares(0.5)};

    border-radius: 50%;
    background: ${(p) => p.theme.color.secondaryOne.main};
`;

function CapabilityItem({ capability, hasCapability }) {
    return (
        <Li hasCapability={hasCapability}>
            <ScreenReaderOnly>
                {hasCapability ? "Does" : "Doesn't"}{" "}
            </ScreenReaderOnly>
            {hasCapability && <Dot />}
        </Li>
    );
}
